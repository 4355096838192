<template lang="pug">
article#article-box
  div.padding._10(@click="_back"): svg(viewBox="0 0 1024 1024"): path(d="M959.296 455.808H276.928l320.64-313.152-78.656-77.568-452.928 446.656 453.824 446.976 77.184-76.864L277.12 564.096h682.176V455.808z")
  div.cat-img: img(:src="obj.cate_top_img")
  section.video.bg.red
    videoBox(:src="player.src" :mode="player.mode")/
  section.center.mod
    h2 {{obj.at_title}}
    div.small.txt.grey.rel.mod.dt
      span 发布时间：{{obj.atcreate_time}}
      div.flex.abs
        img(src="@/assets/ico/redheart.png" @click="_zan")
        span {{obj.good_num}}
        img(src="@/assets/ico/toshare.png")
        span {{obj.share_num}}
    div.content.small(v-html="obj.at_con")
  div.fixed.bottom.flex.center.bg.red.mod.small
    div.grow(@click="_zan")
      img(src="@/assets/ico/zan.png")
      | 点赞
    div.grow
      img(src="@/assets/ico/share2.png")
      | 分享
</template>
<script>
import videoBox from '@/components/modal/video'
export default {
  components: {videoBox},
  props: {
    obj: Object
  },
  data(){return {
    player: {},
  }},
  watch: {
    obj(o){
      if(o.atid){
        let {mp3_url: mp3, video_url: mp4} = o
        let isAudio = mp3.length > 10
        this.player = {mode: isAudio? 'audio': 'video', src: isAudio? mp3: mp4}
      }
    }
  },
  methods: {
    _back(){
      this.$router.go(-1)
    },
    _zan(){
      this.$req.articleZan({atid: this.obj.atid}, (res)=>{
        res.setInc/1 && this.obj.good_num++
        this.$store.commit('tipShow', res.tip)
      })
    },
  },

}
</script>
<style lang="less" scoped>
@import "~@/assets/less/spacing.less";
@import "~@/assets/less/func.less";
#article-box{
  .cat-img img{width: 100%; display: block;}
  .__rem(80, padding-bottom);
  svg{ fill: #575757; .__wh(22, 22);}
  .abs.flex{
    right: 0; top: calc(50% - 0.5rem);
    img{
      .__spacing(margin, 0, 3);
      .__wh(16, 16);
      &::after{
        content: attr(title);
        display:block;
      }
    }
  }
  .content{
    text-align: left;
    line-height: 1.6;
  }
  .fixed.bottom{
    img{ .__wh(16, 16); .__rem(-2, margin-bottom); .__rem(10, margin-right);}
  }
}

</style>