<template lang="pug">
idBox(:obj="article")/
</template>
<script>
import idBox from '@/components/article/id'
export default {
  components: {idBox},
  data(){return {
    article: {},
  }},
  mounted(){
    let {id: atid} = this.$route.params
    this.$req.articleId({atid}, (res)=>{
      this.article = res
    })
  }
}
</script>